import React,  {useMemo} from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import NewsDetails from "../components/OurCompany/News/NewsDetails";

import Favicon from "../images/Favicon.png";

import {NewsData} from '../components/OurCompany/News/News.data'

const NewsDetailsPage = () => {
    const id = useMemo(() => {
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');

            return !isNaN(id) ? parseInt(id) : 0;
        } catch (e) {
            console.error(e);
            return 0;
        }
    }, []);
    // const id = typeof window !== 'undefined' ? window.history.state?.index : 1;
    let data = NewsData[id];
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>News - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                    content="Stay updated with the latest news in the Last-Mile Delivery industry. Gain insights on first-party delivery and third-party delivery, safety, productivity, and industry innovations. Learn how Drivosity is leading the way in last-mile delivery solutions. Read more today."/>
                <meta name="description" content={data.data.metadesc}/>
            </Helmet>
            <Layout component={<NewsDetails/>}/>
        </>
    )
}

export default NewsDetailsPage;
